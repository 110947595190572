import React from 'react';
import { IconProps } from '.';

interface ItemIconProps extends IconProps {
  className?: string;
}

const ItemIcon: React.FunctionComponent<ItemIconProps> = ({ color, className }) => {
  return (
    <svg
      width="64"
      height="65"
      className={className}
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="64" height="64" rx="32" fill={color} />
      <path
        d="M31.92 19.4468L28.7067 26.9268H25.4933C24.96 26.9268 24.44 26.9668 23.9333 27.0734L25.2667 23.8734L25.32 23.7534L25.4 23.5401C25.44 23.4468 25.4667 23.3668 25.5067 23.3001C27.0533 19.7134 28.7867 18.5934 31.92 19.4468Z"
        fill="white"
      />
      <path
        d="M40.9733 27.2868L40.9467 27.2734C40.1467 27.0468 39.3333 26.9268 38.5067 26.9268H30.16L33.16 19.9534L33.2 19.8601C33.3867 19.9268 33.5867 20.0201 33.7867 20.0868L36.7333 21.3268C38.3733 22.0068 39.52 22.7134 40.2267 23.5668C40.3467 23.7268 40.4533 23.8734 40.56 24.0468C40.68 24.2334 40.7733 24.4201 40.8267 24.6201C40.88 24.7401 40.92 24.8468 40.9467 24.9668C41.1467 25.6468 41.16 26.4201 40.9733 27.2868Z"
        fill="white"
      />
      <path
        d="M40.3867 29.1934C39.7867 29.0201 39.16 28.9268 38.5067 28.9268H25.4933C24.5867 28.9268 23.7333 29.1001 22.9333 29.4468C20.6133 30.4468 18.9867 32.7534 18.9867 35.4334V38.0334C18.9867 38.3534 19.0133 38.6601 19.0533 38.9801C19.3467 43.2201 21.6133 45.4868 25.8533 45.7668C26.16 45.8068 26.4667 45.8334 26.8 45.8334H37.2C42.1333 45.8334 44.7333 43.4868 44.9867 38.8201C45 38.5668 45.0133 38.3001 45.0133 38.0334V35.4334C45.0133 32.4868 43.0533 30.0068 40.3867 29.1934ZM35.3333 38.8334H28.6667C28.12 38.8334 27.6667 38.3801 27.6667 37.8334C27.6667 37.2868 28.12 36.8334 28.6667 36.8334H35.3333C35.88 36.8334 36.3333 37.2868 36.3333 37.8334C36.3333 38.3801 35.88 38.8334 35.3333 38.8334Z"
        fill="white"
      />
    </svg>
  );
};

export default ItemIcon;
