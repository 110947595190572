import React from 'react';
import clsx from 'clsx';

interface IProps {
  dark?: boolean;
  padding?: boolean;
}

const Card: React.FunctionComponent<IProps> = ({ dark, padding = true, children }) => {
  return (
    <div
      className={clsx(
        'rounded-[20px] max-w-[593px] w-full text-lg md:text-2xl	mohave-font font-normal',
        {
          'bg-black-card text-white	': dark,
          'px-9 py-6': padding,
        },
      )}
    >
      {children}
    </div>
  );
};

export default Card;
