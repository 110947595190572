import React from 'react';
import GButton from 'components/global/button';
import './_styles.scss';

const imageSrc = 'icons/pre-launch-icon.svg';

const PreLaunch: React.FunctionComponent = () => {
  return (
    <div className="pre-launch-section" style={{ maxWidth: '1180px' }}>
      <div className="info">
        <p className="mohave-font title">Pre launch</p>
        <p className="notable-font desc">We’re Launching Soon…</p>
        <GButton className="join-btn">Join The Discord</GButton>
      </div>
      <img src={imageSrc} alt="panda" />
    </div>
  );
};
export default PreLaunch;
