import React from 'react';
import TeamCard from './TeamCard';
import './_styles.scss';

export interface TeamItem {
  icon: string;
  title: string;
  member: string;
}

const Blue2Panda = 'images/toys/Blue2Panda.png';
const BluePanda = 'images/toys/BluePanda.png';
const PinkPanda = 'images/toys/PinkPanda.png';
const PurplePanda = 'images/toys/PurplePanda.png';

const data: TeamItem[] = [
  { icon: Blue2Panda, title: 'Poppa Panda', member: 'Founder' },
  { icon: BluePanda, title: 'Schwifty Panda', member: 'Founder' },
  { icon: PinkPanda, title: 'Big Brain Panda', member: 'Lead Developer' },
  { icon: PurplePanda, title: 'Super Panda', member: 'Marketing Manager' },
];

const MeetTheTeam: React.FunctionComponent = () => {
  return (
    <div className="meat-the-team">
      <p className="title">Meet the Team</p>
      <div className="team-container">
        {data.map(item => (
          <TeamCard key={item.title} item={item} />
        ))}
      </div>
    </div>
  );
};

export default MeetTheTeam;
