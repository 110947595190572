import ColorPalette from 'libs/ColorPalette';

type RoadMapType = {
  title: string;
  description: string;
  image: string;
  color: string;
};

const OrangePandaImage = 'images/toys/OrangePanda.png';
const PinkPandaImage = 'images/toys/PinkPanda.png';

export const RoadMapData: RoadMapType[] = [
  {
    title: 'Exclusive Access to NFT Launchpads',
    description:
      'Enjoy exclusive NFT whitelist access at discounted mint prices with vetted NFT projects that will explode. Ensuring you can lock in your profits from the start.',
    image: OrangePandaImage,
    color: ColorPalette.blue,
  },
  {
    title: 'Weekly ‘NFT Snipe’ Watchlists',
    description:
      'Our exclusive Panda Pad Discord community members will receive special access to our watchlists of different NFT launches that have the potential to go parabolic.',
    image: PinkPandaImage,
    color: ColorPalette.orange,
  },
  {
    title: 'NFTSweep.xyz Launch',
    description:
      "Exclusive NFT snipping tool needed for every flipper's arsenal. NFT Sweep will include state of the art alerts and tracking features (more info coming soon)",
    image: OrangePandaImage,
    color: ColorPalette.purple,
  },
  {
    title: 'NFTSweep.xyz NFT Access Pass Release',
    description:
      '5,000 access passes released to the world. Panda Pad holders will have first hand access at a highly discounted mint.',
    image: PinkPandaImage,
    color: ColorPalette.blue,
  },
  {
    title: 'Panda Token Launch',
    description:
      'Using the secondary Panda Pad sales we are going to gamify holding Panda Pad tokens. During the development process we will be securing liquidity for the token',
    image: OrangePandaImage,
    color: ColorPalette.orange,
  },
  {
    title: 'Bamboo Palace',
    description: 'Stake your Panda for tokens. Proceed to earn airdrops from other projects.',
    image: PinkPandaImage,
    color: ColorPalette.purple,
  },
  {
    title: 'Mutated Pandas',
    description: 'Stake your Panda for tokens. Proceed to earn airdrops from other projects.',
    image: OrangePandaImage,
    color: ColorPalette.blue,
  },
];
