import React from 'react';
import FirstViewPanda from 'components/icons/FirstViewPanda';
import './_styles.scss';

const FirstView: React.FunctionComponent = () => {
  return (
    <div className="bg-page flex flex-col items-center first-view-section">
      <div
        className="flex flex-col items-center w-full icon-container"
        style={{ maxWidth: '1180px' }}
      >
        <div className="flex items-center justify-around w-full">
          <FirstViewPanda size="small" />
          <FirstViewPanda size="large" />
          <FirstViewPanda size="medium" />
        </div>
        <p className="notable-font title">
          The First NFT LaunchPad Giving Holders Exclusive Access To New Projects Before The Public…
        </p>
        <p className="mohave-font description mb-3">
          Panda Pad Holders Are Guaranteed To Have Exclusive Access To The World&apos;s GREATEST NFT
          Projects Before Anyone Else…
        </p>
      </div>
    </div>
  );
};
export default FirstView;
