import React from 'react';
import './_styles.scss';

interface Social {
  icon: string;
  text: string;
}

const items: Social[] = [
  { icon: '/images/footer-opensea.svg', text: 'OpenSea' },
  { icon: '/images/footer-discord.svg', text: 'Discord' },
  { icon: '/images/footer-twitter.svg', text: 'Twitter' },
];

const Footer: React.FunctionComponent = () => {
  const BluePandaImage = 'images/toys/BluePanda.png';
  const OrangePandaImage = 'images/toys/OrangePanda.png';
  const Pink2PandaImage = 'images/toys/Pink2Panda.png';
  return (
    <footer className="footer-section">
      <div className="flex mx-auto">
        <img
          src={BluePandaImage}
          alt="Panda"
          className="mx-2 sm:mx-4 sm:mx-6 w-[72px] sm:w-[128px] md:w-[188px]"
        />
        <img
          src={OrangePandaImage}
          alt="Panda"
          className="mx-2 sm:mx-4 sm:mx-6 w-[72px] sm:w-[128px] md:w-[188px]"
        />
        <img
          src={Pink2PandaImage}
          alt="Panda"
          className="mx-2 sm:mx-4 sm:mx-6 w-[72px] sm:w-[128px] md:w-[188px]"
        />
      </div>
      <div className="flex items-center mt-12">
        <span className="white-dot mr-4">
          <span className="orange-dot" />
        </span>
        <p className="name ml-4">PANDA pad</p>
      </div>
      <div className="flex items-center mt-[68px]">
        {items.map(item => (
          <div key={item.text} className="mx-4 flex items-center">
            <img src={item.icon} alt={item.text} />
            <p className="ml-4 footer-social">{item.text}</p>
          </div>
        ))}
      </div>
      <p className="copy-right">Panda Pad NFT. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
