import React from 'react';
import './_styles.scss';
import GButton from 'components/global/button';

interface IHeaderLink {
  text: string;
  id: string;
}

const Header: React.FunctionComponent = () => {
  const items: IHeaderLink[] = [
    { text: 'Perks', id: '#perks' },
    { text: 'Features', id: '#features' },
    { text: 'Roadmap', id: '#roadmap' },
    { text: 'FAQ', id: '#faq' },
  ];

  return (
    <div className="header">
      <p className="notable-font logo">lOGO</p>
      <div className="hidden sm:flex justify-center items-center">
        {items.map(item => (
          <a className="mx-5 mohave-font" key={item.id} href={item.id}>
            {item.text}
          </a>
        ))}
      </div>
      <div className="flex justify-center items-center">
        <GButton>Mint</GButton>
      </div>
    </div>
  );
};
export default Header;
