import React, { useState } from 'react';
import clsx from 'clsx';
import { FAQItem } from 'pages/home-page/components/faq/index';
import MinusIcon from 'components/icons/MinusIcon';
import PlusIcon from 'components/icons/PlusIcon';
import './_styles.scss';

interface FAQItemProps {
  item: FAQItem;
}

const FAQQuestionItem: React.FunctionComponent<FAQItemProps> = ({ item }) => {
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(prevState => !prevState);
    const content = document.getElementById(`${item.key}-answer`);
    if (content) {
      if (content.style.maxHeight) {
        // @ts-ignore
        content.style.maxHeight = null;
      } else {
        content.style.maxHeight = content.scrollHeight + 'px';
      }
    }
  };

  return (
    <div className="faq-item" id={item.key}>
      <button
        type="button"
        onClick={onOpen}
        className={clsx('faq-question', { 'faq-question-open': open })}
      >
        <p>{item.question}</p>
        {open ? <MinusIcon /> : <PlusIcon />}
      </button>
      <div className="faq-answer" id={`${item.key}-answer`}>
        <p>
          PandaPad is built for early supporters who want to help jumpstart the most powerful NFT
          launchpad in the industry with the funding it needs to continue developing great tools and
          assets for members to use. Think of the NFT sale as a kickstarter campaign for the
          platform. In this case, you’re greatly rewarded in perpetuity by reaping the holder
          benefits for LIFE.
        </p>
      </div>
    </div>
  );
};

export default FAQQuestionItem;
