import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { RoadMapData } from 'constants/RoadMap';
import Card from 'components/global/card';
import DashedLine from 'components/global/dashed-line';
import ItemIcon from 'components/icons/ItemIcon';
import BlueBox from './BlueBox';

const roadMap = 'ROADMAP';

const RoadMapSection: React.FunctionComponent = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)',
  });
  return (
    <div id="roadmap" className="max-w-[976px] mx-auto my-8">
      <p className="notable-font text-[28px] text-center mb-[calc(47px-1.5rem)]">{roadMap}</p>
      {RoadMapData.map((data, index) => {
        return (
          <div className="flex items-center justify-center mx-4" key={data.title}>
            {isDesktop && <img className="mt-[64px]" src={data.image} alt="panda" />}
            <div className="flex flex-col ml-4">
              <div className="flex items-center my-6">
                <div className="w-[48px] h-[48px] min-w-[48px] min-h-[48px] md:w-[64px] md:h-[64px] md:min-w-[64px] md:min-h-[64px]">
                  <ItemIcon color={data.color} className="w-[100%] h-[100%]" />
                </div>
                <p className="ml-6 text-[22px] md:text-[28px] font-bold mohave-font">
                  {data.title}
                </p>
              </div>
              <div className="flex">
                <DashedLine />
                <div>
                  <Card padding={false}>{data.description}</Card>
                  {index === 0 && <BlueBox />}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RoadMapSection;
