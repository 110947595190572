import React from 'react';
import { TeamItem } from 'pages/home-page/components/meet/index';
import './_styles.scss';

interface TeamCardProps {
  item: TeamItem;
}

const TeamCard: React.FunctionComponent<TeamCardProps> = ({ item }) => {
  const Discord = '/images/discord.svg';
  const Twitter = '/images/twitter.svg';
  return (
    <div className="card">
      <img className="w-[188px] h-[188px]" src={item.icon} alt={item.title} />
      <p className="card-title">{item.title}</p>
      <p className="card-member">{item.member}</p>
      <div className="flex mt-4">
        <img src={Discord} alt="Discord" className="mx-1" />
        <img src={Twitter} alt="Twitter" className="mx-1" />
      </div>
    </div>
  );
};

export default TeamCard;
