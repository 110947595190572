import React, { ButtonHTMLAttributes } from 'react';
import './_styles.scss';
import clsx from 'clsx';

interface IGButtonProps extends ButtonHTMLAttributes<any> {
  className?: string;
}

const GButton: React.FunctionComponent<IGButtonProps> = ({ children, className, ...props }) => {
  return (
    <button className={clsx('g-button', className)} {...props}>
      {children}
    </button>
  );
};

export default GButton;
