import React from 'react';
import FAQQuestionItem from './FAQQuestionItem';
import './_styles.scss';

export interface FAQItem {
  key: string;
  question: string;
}

const items: FAQItem[] = [
  { key: 'question-1', question: 'Why Should I Buy a PandaPad NFT?' },
  { key: 'question-2', question: 'How much is the PandaPad NFT?' },
  { key: 'question-3', question: 'What blockchain are you operating on?' },
  { key: 'question-4', question: 'How many PandaPad NFTs will there be?' },
  { key: 'question-5', question: 'When can I buy a PandaPad NFT?' },
  { key: 'question-6', question: 'How do I buy a PandaPad NFT?' },
  { key: 'question-7', question: 'Why should I buy this NFT?' },
];

const FAQ: React.FunctionComponent = () => {
  return (
    <div className="faq-section" id="faq">
      <p className="title">Frequently Asked Questions</p>
      {items.map(item => (
        <FAQQuestionItem key={item.key} item={item} />
      ))}
    </div>
  );
};

export default FAQ;
