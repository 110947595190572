import React from 'react';
import HomePage from 'pages/home-page';
import './assets/scss/app.scss';
import Header from 'components/layouts/header';

const App: React.FunctionComponent = () => {
  return (
    <div className="bg-semi-white app">
      <Header />
      <HomePage />
    </div>
  );
};

export default App;
