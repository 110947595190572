import React from 'react';

const BlueBox: React.FunctionComponent = () => {
  return (
    <div
      className="flex items-center justify-center h-[234px] mt-6"
      style={{ backgroundColor: '#01B8FF', border: '3px solid #FF8C0E', borderRadius: '20px' }}
    >
      <p
        className="text-[24px] md:text-[40px]"
        style={{
          fontWeight: 400,
          color: '#FFFFFF',
          fontFamily: 'DaysOne',
          textAlign: 'center',
          lineHeight: '30px',
        }}
      >
        PANDA PAD NFT
      </p>
    </div>
  );
};

export default BlueBox;
