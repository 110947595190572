import React from 'react';
import { PerksItemsRow1, PerksItemsRow2 } from 'constants/Perks';
import PerkItem from 'components/global/perk-item';
import PerkPandaIcon from 'components/icons/PerkPanda';
import './_styles.scss';

const PerksSection: React.FunctionComponent = () => {
  return (
    <div
      id="perks"
      className="flex justify-center flex-col w-full mt-6 sm:px-4 perks-section"
      style={{ maxWidth: '1180px' }}
    >
      <p className="notable-font w-fit mb-3 title">Exclusive Perks</p>
      <p className="mohave-font desc">
        Panda Pad NFT holders are going to receive highly exclusive access to many different
        benefits providing massive utility. Holders should feel excited knowing these are solely
        available to Panda holders.
      </p>
      <div className="flex flex-col md:flex-row justify-center items-center">
        <div>
          {PerksItemsRow1.map(item => (
            <PerkItem
              key={item.title}
              iconColor={item.color}
              title={item.title}
              description={item.description}
              align="RTL"
            />
          ))}
        </div>
        <div className="mx-3 xl:mx-8 hidden lg:flex center-panda-icon">
          <PerkPandaIcon />
        </div>
        <div>
          {PerksItemsRow2.map(item => (
            <PerkItem
              key={item.title}
              iconColor={item.color}
              title={item.title}
              description={item.description}
              align="LTR"
              reverse
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default PerksSection;
