import React from 'react';
import { useMediaQuery } from 'react-responsive';
import ItemIcon from 'components/icons/ItemIcon';
import './_styles.scss';

interface IPerkItemProps {
  iconColor: string;
  title: string;
  description: string;
  align: 'RTL' | 'LTR';
  reverse?: boolean;
}

const PerkItem: React.FunctionComponent<IPerkItemProps> = ({
  title,
  description,
  iconColor,
  align,
  reverse,
}) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)',
  });
  return (
    <div className={`${reverse ? 'perk-item perk-item-reverse' : 'perk-item'}`}>
      {(reverse || !isDesktop) && (
        <div className="icon-container">
          <ItemIcon color={iconColor} />
        </div>
      )}
      <div
        className="flex flex-col"
        style={{ alignItems: align === 'RTL' && isDesktop ? 'flex-end' : 'flex-start' }}
      >
        <p
          className="mohave-font perk-item-title"
          style={{ textAlign: align === 'RTL' && isDesktop ? 'right' : 'left' }}
        >
          {title}
        </p>
        <p
          className="mohave-font perk-item-desc"
          style={{ textAlign: align === 'RTL' && isDesktop ? 'right' : 'left' }}
        >
          {description}
        </p>
      </div>
      {!reverse && isDesktop && (
        <div className="icon-container">
          <ItemIcon color={iconColor} />
        </div>
      )}
    </div>
  );
};
export default PerkItem;
