import ColorPalette from 'libs/ColorPalette';

interface IPerkItem {
  title: string;
  description: string;
  color: string;
}

export const PerksItemsRow1: IPerkItem[] = [
  {
    title: 'Access ‘The Jungle’',
    description: 'Highly sought-after alpha community with watch lists and high level NFT whales',
    color: ColorPalette.orange,
  },
  {
    title: 'Surprise Airdrops',
    description: 'Panda holders will receive NFT airdrops from notable projects before they launch',
    color: ColorPalette.purple,
  },
  {
    title: 'Panda Evolution',
    description:
      'Panda Pad mutation is inevitable and all holders will be in for a massive surprise (SUPER EXCLUSIVE)',
    color: ColorPalette.blue,
  },
];

export const PerksItemsRow2: IPerkItem[] = [
  {
    title: 'Exclusive Access to NFT Launchpads',
    description: 'Exclusive access to projects ONLY accessible to Panda Pad holders.',
    color: ColorPalette.blue,
  },
  {
    title: 'WL Opportunities',
    description: 'Invite only WL access to new projects',
    color: ColorPalette.orange,
  },
  {
    title: 'Private Access',
    description: 'New Panda Pad BETA roll-outs will be available to all holders',
    color: ColorPalette.purple,
  },
];
