import React from 'react';
import './_styles.scss';

const CoreFeatures: React.FunctionComponent = () => {
  const PinkPandaImage = 'images/toys/PinkPanda.png';
  const Pink2PandaImage = 'images/toys/Pink2Panda.png';
  const Blue2PandaImage = 'images/toys/Blue2Panda.png';
  return (
    <div id="features" className="core-features-section">
      <div className="content">
        <p className="title">
          7 core features inside the Panda Pad Launchpad that all holders will receive (EXTREMELY
          EXCLUSIVE).
        </p>
        <div className="grid-container">
          <div className="box-1">
            <div className="white-box">
              <p className="sub-title">🔥 Getting access to HOT projects can be difficult</p>
              <p className="sub-description">
                We are going to broker deals with new NFT projects & pre existing NFT projects to
                obtain early access and whitelist slots exclusively for our Panda Pad holders
                allowing you to become profitable instantly upon minting.
              </p>
            </div>
            <div className="w-full mt-5 flex justify-center">
              <img className="mx-4" src={Pink2PandaImage} alt="Panda" />
              <img className="mx-4" src={Blue2PandaImage} alt="Panda" />
              <img className="mx-4" src={PinkPandaImage} alt="Panda" />
            </div>
          </div>
          <div className="white-box box-2">
            <p className="list-item-text">🚀 NFT Launchpad</p>
            <p className="list-item-text">⚡️ Alpha Info Per Launch</p>
            <p className="list-item-text">🥇 Exclusive Panda ONLY NFTs</p>
            <p className="list-item-text">💎️ Success Projection Tools</p>
            <p className="list-item-text">💻 Developer/Team Information</p>
            <p className="list-item-text">🎺️ Automated Alerts</p>
            <p className="list-item-text">🔥 Private Mint Pages</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoreFeatures;
