import React from 'react';
import CoreFeatures from './components/core-features';
import FAQ from './components/faq';
import FirstView from './components/first-view';
import Footer from './components/footer';
import MeetTheTeam from './components/meet';
import PerksSection from './components/perks';
import PreLaunch from './components/pre-launch';
import RoadMap from './components/road-map';

const HomePage: React.FunctionComponent = () => {
  return (
    <div className="flex justify-center items-center flex-col mx-auto">
      <FirstView />
      <div className="px-4 w-full flex flex-col items-center">
        <PreLaunch />
        <PerksSection />
      </div>
      <CoreFeatures />
      <RoadMap />
      <MeetTheTeam />
      <FAQ />
      <Footer />
    </div>
  );
};
export default HomePage;
